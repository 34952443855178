import React from "react"
import {RiArrowLeftSLine, RiBugLine, RiSkullLine} from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import CustomButton from "../components/CustomButton"

const NotFound = () => (
  <Layout className="not-found-page">
    <SEO title="Sidan hittades inte"/>
    <div style={{
      textAlign: 'center',
      marginTop: '2rem'
    }}>
      <header>
        <RiSkullLine style={{
          fontSize: "128px",
        }}/>
        <h1>Hoppsan, något gick fel!</h1>
        <p>Vi har precis uppdaterat vår hemsida och du kan ha hamnat på en gammal sida eller länk som inte längre finns. Du får gärna kontakta oss om du tror att det är något som behöver åtgärdas, om du behöver hjälp eller har frågor.</p>
      </header>

      <CustomButton link="/" lightGreen>
        <RiArrowLeftSLine style={{marginRight: '0.5em'}}/>Tillbaka till startsidan
      </CustomButton>

      <br/><br/>

      <CustomButton link="/kontakta-oss">
        Kontakta oss
      </CustomButton>

    </div>
  </Layout>
)

export default NotFound
